<template>
<div id="tips-add-questions-table">
      <Loader v-if="loading" rows="20" cols="3"></Loader>
      <Alert v-else-if="questions.length <= 0" variant="info" >Här fanns inga frågor att visa just nu!</Alert>
      <b-container fluid v-else>
            <b-row no-gutters v-for="(q, index) in questions" :key="q.id" class="tips-add-row align-items-center border-bottom py-3">
                  <b-col cols="8" class="tips-question-title">
                        Fråga {{index+1}}.
                  </b-col>
                  <b-col cols="4" class="text-right">
                        {{q.points}} poäng
                  </b-col>
                  <b-col cols="12">
                        {{q.question}}
                  </b-col>
                  <b-col cols="12" md="10">
                        <div v-if="isopen" :id="'q_'+q.id">
                              <b-form-input :id="'qinput_'+q.id" v-if="q.type == 1" :value="getUserAnswer(q.id)" @change="SaveQuestion(index, q.id,$event)"></b-form-input>
                              <b-select :id="'qinput_'+q.id" v-if="q.type == 2" :value="getUserAnswer(q.id)" :options="getOptionsArray(q)" @change="SaveQuestion(index, q.id,$event)"></b-select>
                        </div>
                        <div v-else>
                              <span v-html="closedHtml(q)"></span>
                        </div>
                  </b-col>
                  <b-col cols="12" md="2" class="text-right">
                        <div v-if="isopen">
                              <countdown :left-time="timeleft()" @finish="Finished(q)">
                                    <span slot="process" slot-scope="{ timeObj }">
                                          <div v-if="timeObj.d <= 0">
                                                {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
                                          </div>
                                          <div v-else-if="timeObj.d == 1">
                                                {{timeObj.d}} dag
                                          </div>
                                          <div v-else>
                                                {{timeObj.d}} dagar
                                          </div>
                                    </span>
                                    <span slot="finish">
                                          Stängd
                                    </span>
                              </countdown>
                        </div>
                        <div v-else>
                              Stängd
                        </div>
                  </b-col>
            </b-row>
      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
      name: "AddQuestionsTable",
      components: {
            Alert,
            Loader
      },
      data() {
            return {};
      },
      computed: {
            loading() {
                  return this.$store.getters["tipsg/myquestionsloading"];
            },
            questions() {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        return data.questions;
                  }
                  return [];
            },
            userAnswers() {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        return data.answers;
                  }
                  return [];
            },
            closeDate() {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        var closedate = data.start;
                        return closedate;
                  }
                  return false;
            },
            isopen() {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        var closedate = data.start;
                        // har passerat?
                        let isopen = this.$date.isOpen(closedate);
                        return isopen;
                  }
                  return false;
            }
      },
      methods: {
            getGroupTeamsOptions(gid) {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        var allteams = {};
                        if (data.teams[gid]) {
                              var gteams = data.teams[gid];
                              for (var i = 0; i < gteams.length; i++)
                                    allteams[gteams[i].id] = gteams[i];
                        }
                        let tarray = [];
                        for (var id2 in allteams) {
                              tarray.push(allteams[id2].dispname);
                        }
                        return tarray.sort();
                  }
                  return [];
            },
            getAllteamsOptions() {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        var allteams = {};
                        for (var gid in data.teams) {
                              var gteams = data.teams[gid];
                              for (var i = 0; i < gteams.length; i++)
                                    allteams[gteams[i].id] = gteams[i];
                        }
                        let tarray = [];
                        for (var id2 in allteams) {
                              tarray.push(allteams[id2].dispname);
                        }
                        return tarray.sort();
                  }
                  return [];
            },
            getOptionsArray(q) {
                  var opt = [];
                  opt.push("");
                  if (q.options && q.options.length > 0) {
                        for (var i = 0; i < q.options.length; i++) {
                              var e = q.options[i];
                              if (e.entry == "ALLTEAM") {
                                    opt = this.getAllteamsOptions();
                                    return opt;
                              } else if (e.entry == "GROUP") {
                                    opt = this.getGroupTeamsOptions(q.groupid);
                                    return opt;
                              }
                              opt.push(e.entry);
                        }
                  }
                  return opt;
            },
            getOptionsString(q) {
                  var opt = this.getOptionsArray(q);
                  var str = opt.join(", ");
                  switch (str) {
                        case "ALLTEAM":
                        case "GROUP":
                              return "";
                        default:
                              return str;
                  }
            },
            hasOptions(q) {
                  var arr = this.getOptionsArray(q);
                  if (arr && arr.length > 0)
                        return true;
                  return false;
            },
            UserAnswerClass(question) {
                  return this.UserAnswerCorrect(question) + "";
            },
            UserAnswerCorrect(question) {
                  var ans = this.getAnswersArray(question);
                  var ua = this.getUserAnswer(question.id);
                  if (ans.includes(ua))
                        return true;
                  return false;
            },
            getAnswersArray(q) {
                  var ans = [];
                  if (q.answers && q.answers.length > 0) {
                        for (var i = 0; i < q.answers.length; i++) {
                              var e = q.answers[i];
                              ans.push(e.entry);
                        }
                  }
                  return ans;
            },
            getAnswersString(q) {
                  var ans = this.getAnswersArray(q);
                  if (ans.length > 0)
                        return ans.join(", ");
                  return "&nbsp;";
            },
            getUserAnswer(questionid) {
                  var answers = this.userAnswers;
                  if (answers && answers[questionid]) {
                        return answers[questionid].answer;
                  }
                  return "&nbsp;";
            },
            closedHtml(q) {
                  var extraClass = this.UserAnswerClass(q);
                  var ans = this.getUserAnswer(q.id);
                  var div = "<div class='mt-3 tips-add-entry text-left" + extraClass + "'>";
                  div += "<span class='font-weight-bold'>Ditt svar:</span> <span class='font-italic'>" + ans + "</span></div>";
                  return div;
            },
            timeleft() {
                  var data = this.$store.getters["tipsg/myquestions"];
                  if (data) {
                        var closedate = data.start;
                        return this.$date.TimeLeft(closedate);
                  }
                  return "Error";
            },
            Finished(q) {
                  var id = "q_" + q.id;
                  var div = document.getElementById(id);
                  var ans = this.closedHtml(q);
                  div.innerHTML = ans;
            },
            ShowToast(title, text, variant) {
                  this.$bvToast.toast(text, {
                        title: title,
                        variant: variant,
                        solid: true,
                  });
            },
            SaveQuestion(index, qid, value) {
                  if (!value)
                        value = "";
                  var postdata = {
                        "questionid": qid,
                        "value": value.trim()
                  };
                  let input = document.getElementById("qinput_" + qid);
                  this.$store.dispatch("tipsp/SaveQuestion", postdata).then((response) => {
                        if (response.data.status == "ok") {
                              // sparat
                              this.ShowToast("Sparat", "Ditt svar '" + value + "' på fråga " + (index + 1) + " har sparats!", "success");
                              input.classList.remove("bg-danger");
                        } else {
                              // error 
                              var error = response.data.errors.join(", ");
                              this.ShowToast("Fråga " + (index + 1) + ": FEL!", error, "danger");
                              var uans = this.getUserAnswer(qid);
                              input.classList.add("bg-danger");
                              input.value = uans;
                        }
                        this.$store.dispatch("tipsg/getAnswered");
                  });
            }
      }
};
</script>
